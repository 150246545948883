import crypto from 'crypto'

export const twoCTwoPPayload = {
  secretKey: `${process.env.VUE_APP_2C2P_SECRET_ID}`,
  merchantId: `${process.env.VUE_APP_2C2P_MERCHAN_ID}`,
  resultUrl1: `${process.env.VUE_APP_API_URL}/public/orders/2c2p/redirect/`,
  resultUrl2: `${process.env.VUE_APP_API_URL}/public/orders/2c2p`,
  paymentUrl: `${process.env.VUE_APP_2C2P_PAYMENT_URL}`,
  version: '8.5',
  currency: '764'
}

export function get2C2PpaymentData (billUrl, billId, payAmount, currency) {
  function hashValue2C2P (tpayment) {
    const params = tpayment.version
      + tpayment.merchantId
      + tpayment.paymentDescription
      + tpayment.orderId
      + tpayment.currency
      + tpayment.amount
      + tpayment.resultUrl1
      + tpayment.resultUrl2
    return crypto
      .createHmac('sha256', tpayment.secretKey)
      .update(params)
      .digest('hex')
  }

  function genAmountFor2c2p (payload) {
    const amount = parseFloat(payload).toFixed(2)
    const num = parseInt(amount.split('.').join(''))
    return num.toLocaleString(undefined, {
      minimumIntegerDigits: 12,
      useGrouping: false
    })
  }

  const tpayment = {
    ...twoCTwoPPayload,
    resultUrl1: `${twoCTwoPPayload.resultUrl1}${billUrl}`,
    paymentDescription: `${billId}`,
    orderId: `${billId}`,
    amount: genAmountFor2c2p(payAmount),
    currency
  }

  return {
    ...tpayment,
    hashValue: hashValue2C2P(tpayment)
  }
}
