<template>
  <div>
    <div
      v-if="isNotFound"
      class="centered">
      <lottie-player
        src="https://assets8.lottiefiles.com/packages/lf20_t24tpvcu.json"
        background="transparent"
        speed="1"
        style="width: 300px; height: 300px;"
        loop
        autoplay></lottie-player>
      <h3 class="text-center mt-10">
        {{ $t('global.notFoundOrder') }}
      </h3>
    </div>
    <div
      v-if="isPaymentSuccess"
      class="centered">
      <lottie-player
        src="https://assets2.lottiefiles.com/packages/lf20_gaxn5gzy.json"
        background="transparent"
        speed="1"
        style="width: 300px; height: 300px;"
        loop
        autoplay></lottie-player>
      <h3 class="text-center mt-8 mb-4">
        {{ $t('global.paidSuccess') }}
      </h3>
      <div class="d-flex justify-center">
        <a
          v-if="isFacebook"
          class="text-decoration-none"
          :href="`${url}/history`">
          <v-btn
            color="#38C071"
            dark>
            {{ $t('global.backToHistory') }}
          </v-btn>
        </a>
        <a
          v-else
          class="text-decoration-none"
          :href="`${url}/liff/history`">
          <v-btn
            color="#38C071"
            dark>
            {{ $t('global.backToHistory') }}
          </v-btn>
        </a>
      </div>
    </div>
    <div v-if="ready">
      <div class="centered">
        <lottie-player
          src="https://assets7.lottiefiles.com/packages/lf20_yzoqyyqf.json"
          background="transparent"
          speed="1"
          style="width: 300px; height: 300px;"
          loop
          autoplay></lottie-player>
        <h3 class="text-center mt-10">
          {{ $t('global.direction2c2p') }}
        </h3>
      </div>
      <form
        id="2C2PPaymentSubmitForm"
        name="SubmitForm"
        method="post"
        :action="f2C2Ppayment.paymentUrl">
        <input
          type="hidden"
          name="version"
          :value="f2C2Ppayment.version">
        <input
          type="hidden"
          name="merchant_id"
          :value="f2C2Ppayment.merchantId">
        <input
          type="hidden"
          name="currency"
          :value="f2C2Ppayment.currency">
        <input
          type="hidden"
          name="result_url_1"
          :value="f2C2Ppayment.resultUrl1">
        <input
          type="hidden"
          name="result_url_2"
          :value="f2C2Ppayment.resultUrl2">
        <input
          type="hidden"
          name="hash_value"
          :value="f2C2Ppayment.hashValue">
        <input
          type="hidden"
          name="payment_description"
          :value="f2C2Ppayment.paymentDescription">
        <input
          type="hidden"
          name="order_id"
          :value="f2C2Ppayment.orderId">
        <input
          type="hidden"
          name="amount"
          :value="f2C2Ppayment.amount">
      </form>
    </div>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import axios from '../../utils/axios.js'
// import _ from 'loadash'
import {
  get2C2PpaymentData
} from './2c2p.js'

export default {
  data () {
    return {
      order: null,
      ready: false,
      isPaided: false,
      isPaid: false,
      isPaymentSuccess: false,
      isNotFound: false,
      f2C2Ppayment: {}
    }
  },
  computed: {
    bill () {
      return ''
    },
    isFacebook () {
    const { member } = JSON.parse(localStorage.getItem('member'))
    if (member && member.facebookId) {
      return true
    }
    return false
    },
    url () {
      return process.env.VUE_APP_FRONTEND_URL
    }

  },
  async mounted () {
    this.main()
  },
  methods: {
    async main () {
      try {
          const { data: { data } } = await axios.get(`/order/${this.$route.params.orderId}`)
              if (data && data.orderStatus !== 'paid') {
                const order = JSON.parse(localStorage.getItem('order'))
                const payload = {
                  memberId: order.memberId,
                  orderId: order.orderId,
                  discount: order.discount,
                  point: order.point,
                  package: order.package,
                  total: order.total
                }
                await axios.post('/transaction', payload)
                this.ready = true
                const currency = '764'
                const randUrl = nanoid()
                const price = order.total
                localStorage.removeItem('order')
                if (price > 0) {
                  this.f2C2Ppayment = get2C2PpaymentData(
                    randUrl,
                    this.$route.params.orderId, // orderId
                    price, // amount
                    currency
                  )
                  setTimeout(() => {
                    document.getElementById('2C2PPaymentSubmitForm').submit()
                  }, 3000)
                } else {
                  this.ready = false
                  this.isPaymentSuccess = true
                }
              } else {
                this.isNotFound = true
              }
      } catch (error) {
        console.error(error)
      }
    },
    async getOrder () {
      try {
        const { data: { data } } = await axios.get(`${process.env.VUE_APP_API_URL}/order/${this.$route.params.orderId}`)
        this.order = data
      } catch (error) {
       console.error()
      }
    },
    async checkStatusOrder () {
      try {
        const { data: { data } } = await axios.get(`${process.env.VUE_APP_API_URL}/order/${this.$route.params.orderId}`)
        if (!data) {
          this.isNotFound = true
        }
        if (data.orderStatus === 'paid') {
          this.isPaided = true
        }
        if (!data.orderStatus === 'paid') {
          this.ready = true
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
<style scoped>
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>
